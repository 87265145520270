.animated {
    color: #fff;
    background-color: #f7ac16;
    border-radius: 50%;
}
.unanimated {
    color: #fff;
}

.showPoints {
    width: auto;
    margin-left: .3rem;
    margin-right: .8rem;
    max-width: 5rem;
    -webkit-transition: max-width 2s ease-in-out;
    -moz-transition: max-width 2s ease-in-out;
    -o-transition: max-width 2s ease-in-out;
    transition: max-width 2s ease-in-out;
}

.hidePoints {
    width: 0;
    overflow: hidden;
    -webkit-transition: max-width 2s ease-in-out;
    -moz-transition: max-width 2s ease-in-out;
    -o-transition: max-width 2s ease-in-out;
    transition: max-width 2s ease-in-out;
}

.pointsContainer {
    background: #fff;
    width: auto;
    min-width: 2rem;
    -webkit-transition: min-width 2s ease-in-out;
    -moz-transition: min-width 2s ease-in-out;
    -o-transition: min-width 2s ease-in-out;
    transition: min-width 2s ease-in-out;
}