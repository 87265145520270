@import "../../styles/mixins";

.video {
    width: 100%;
    height: 170px;

    @include for-eleven-phone-devices{
      height: 221px;
    }

    @include for-large-tablet-devices {
      height: 421px;
      background: black;
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}