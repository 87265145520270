@mixin for-extra-small-devices {
    @media (min-width: 320px) { @content; }
}
// @mixin for-small-devices
@mixin for-medium-phone-devices {
    @media (min-width: 375px) { @content; }
}
// iPhone11
@mixin for-eleven-phone-devices {
    @media (min-width: 410px) { @content; }
}
// @mixin for-medium-devices
@mixin for-large-phone-devices {
    @media (min-width: 425px) { @content; }
}
// @mixin for-large-devices
@mixin for-small-tablet-devices {
    @media (min-width: 500px) { @content; }
}
// @mixin for-extra-large-devices
@mixin for-medium-tablet-devices {
    @media (min-width: 600px) { @content; }
}
@mixin for-large-tablet-devices {
    @media (min-width: 760px) { @content; }
}
@mixin for-small-desktop-devices {
    @media (min-width: 1024px) { @content; }
}
@mixin for-medium-desktop-devices {
    @media (min-width: 1440px) { @content; }
}
