.filter {
  &_tabs {
    margin-top: 0 !important;
    background: #efeeee;
    box-shadow: -12px -12px 20px 0 rgba(255, 255, 255, 0.5), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);
  }

  &_label {
    font-weight: 200 !important;
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .units {
    margin-left: auto;
    font-size: 12px;
    font-weight: normal !important;
  }

  &_active-indicator {
    height: 6px;
    width: 6px;
    background-color: var(--primary-color);
    border-radius: 50%;
    position: absolute;
    top: calc(50% + 13px);
    left: calc(50% - 3px);
  }

  .MuiButtonBase-root.MuiAccordionSummary-root , .MuiAccordionDetails-root {
    padding: 0;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #C8BFBF;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 4px;
    padding-left: 0;
  }

  p.MuiTypography-root {
    font-weight: 200;
  }

  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: var(--primary-color);
  }

  .MuiButtonBase-root.MuiButton-root:hover {
    background-color: var(--primary-color);
  }

  .MuiTabs-indicator {
    background-color: transparent;
  }
}