.recipe-accordion-checklist-item {
    .MuiFormControlLabel-root {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .MuiRadio-root {
        padding: 3px !important;
    }
}

.recipe_accordion {
    .strikethrough {
        text-decoration: line-through;
    }
}