@import "../../styles/styleForExtend";
@import "../../styles/mixins";

.page-header {
    & .title {
        @media (max-width: 480px) {
            font-size: 1.3rem;
        }
    }

    & .MuiAccordionSummary-content.Mui-expanded p {
        color: #6C6C6C;
    }

    & .MuiPaper-root.MuiAccordion-root.Mui-expanded::before {
        opacity: 1 !important;
    }

    & .icon {
      width: 4rem;
      height: 4rem;
      object-fit: contain;

      &-large{
        width: 6rem;
      }

      @include for-large-tablet-devices {
        width: 6rem;
        height: 6rem;

        &-large{
          width: 6rem;
        }
      }
    }
}