@import "../../styles/styleForExtend";
@import "../../styles/mixins";

.card {
  &_truncate {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  &_logo-img {
    width: 28%;
    max-width: 192px;
  }
  &_menu {
    &_tile_wrap {
      background: transparent;
      box-shadow:  -12px -12px 20px 0 RGBA(255, 255, 255, 0.5), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);
      // filter: drop-shadow(-7px -7px 11px rgba(255, 255, 255, 0.2));
      // filter: drop-shadow(7px 7px 11px rgba(209, 205, 199, 0.5));
      // border: 1px solid rgba(255, 255, 255, 0.2);
      // filter: drop-shadow(-12px -12px 20px rgba(255, 255, 255, 0.2));
      // filter: drop-shadow(12px 12px 20px RGBA(209, 205, 199, 0.5));
    }

    &_tile {
      background: url('../../shared/svg/card-shape.svg') center center no-repeat;
      background-size: cover;
      // clip-path: polygon(0% 4%, 4% 4%, 4% 0%, 96% 0%, 96% 4%, 100% 4%, 100% 96%, 96% 96%, 96% 100%, 4% 100%, 4% 96%, 0% 96%);
    }

    &-label {
      margin: 5px 0 5px 0;
      line-height: 1;
      height: 2.3rem;
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: 1rem;

      @include for-eleven-phone-devices {
        margin: 0;
      }

      @include for-medium-phone-devices {
        font-size: 1.2rem;
      }
    }

    &-background {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: -12px -12px 20px 0 rgba(255, 255, 255, 0.2), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);

      &:hover {
        opacity: 1;
      }

      &::after {
        content: "";
        display: block;
        padding-top: 100%;
        @include for-medium-tablet-devices {
          display: none;
        }
      }

      @include for-medium-tablet-devices {
        /*idth: 13rem;*/
      }

      @include for-large-tablet-devices {
        width: 100%;
        height: 100%;
        padding: 10px 0px;
      }
    }

    &-icon {
      width: 4rem;
      height: 4rem;
      object-fit: contain;

      &-large{
        width: 6rem;
      }

      @include for-large-tablet-devices {
        width: 12rem;
        height: 12rem;

        &-large{
          width: 18rem;
        }
      }
    }

    &-item {
      padding: 0 15px 15px 15px;
      text-align: center;
      width: 100%;
      max-width: 500px;
      color: var(--text-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--background-color);

      @include for-large-tablet-devices {
        max-width: 300px;
      }
    }
  }
}
