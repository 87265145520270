@import 'mixins';

.page-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.info-description {
  color: var(--text-color);
  text-align: center;
  font-size: 14pt;
}

.btn {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 50px;
  }

  &-description {
    padding: 7px;
    text-align: center;
    width: calc(100% - 14px);
    max-width: 500px;
    color: var(--text-color);
    font-size: 12pt;
    margin: 10px 0px;

    @include for-small-tablet-devices {
      font-size: 15pt;
    }
  }
}

.action-btn {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 10px 30px;
  background-color: var(--button-color);
  border: 1px solid var(--button-color);
  color: var(--button-text-color);
  transition: background-color 1.1ms ease-in-out;
  font-size: 15pt;

  @include for-medium-phone-devices {
    height: 60px;
  }

  @include for-large-phone-devices {
    height: 65px;
    font-size: 17pt;
  }

  @include for-small-tablet-devices {
    font-size: 20pt;
    height: 76px;
  }
  &:hover {
    background-color: rgb(58, 58, 58);
  }

  &:focus {
    outline: 0;
    background-color: rgb(58, 58, 58);
  }
}
