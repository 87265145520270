.beef-product {
  &_img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: contain;

    &_container {
      border: none;
      padding: 0;
      width: 100%;
    }
  }
}