body {
  margin: 0;
  background: var(--background-color);
  font-family: var(--primary-font);
  -webkit-font-smoothing: var(--primary-font);
  -moz-osx-font-smoothing: var(--primary-font);
  --button-color: #fff;
  --primary-color: #000000;
  --background-color: #f4f2f0;
  --background-color-modal: #00000094;

  --button-text-color: #ffffff;

  --text-color: #4b4b4b;

  --primary-font: "Oswald";
  --card-text-font: "Helvetica 45 Light", sans-serif;
  --nutrition-font: 'Inter', sans-serif;

  --logo-primary-color: #fff;
  --background-block-card: RGB(239, 238, 238);
}
