.footer-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 80px;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 80px;
  border-top-left-radius: 14px;
  -webkit-clip-path: url(#scoop);
  clip-path: url(#scoop);
  cursor: pointer;

  &_border {
    width: 30px;
    height: 25px;
    position: absolute;
    top: 5px;

    &-size {
      width: 12px;
      height: 12px;
    }

    &-left-top {
      border-top-left-radius: 33%;
      border-top: 2px solid var(--button-text-color);
      border-left: 2px solid var(--button-text-color);
      position: absolute;
      top: 10px;
      left: 14px;
    }

    &-left-bottom {
      border-bottom-left-radius: 33%;
      border-bottom: 2px solid var(--button-text-color);
      border-left: 2px solid var(--button-text-color);
      position: absolute;
      top: 45px;
      left: 14px;
    }

    &-right-top {
      border-top-right-radius: 33%;
      border-top: 2px solid var(--button-text-color);
      border-right: 2px solid var(--button-text-color);
      position: absolute;
      top: 10px;
      left: 70px;
    }

    &-right-bottom {
      border-bottom-right-radius: 33%;
      border-bottom: 2px solid var(--button-text-color);
      border-right: 2px solid var(--button-text-color);
      position: absolute;
      top: 45px;
      left: 70px;
    }
  }

  &_text {
    position: absolute;
    color: var(--button-text-color);
    font-size: 14px;
    text-decoration: none;
    top: 15px;
    left: 27px;
  }
}
