.accordion_section {
  position: relative;

  .recipe {
    &_radio {
      height: 24px;
      width: 24px;
      margin: auto 6px;
    }

    &_accordion_title {
      font-size: 2rem;
      color: #010101;
      text-transform: uppercase;
      font-weight: bold;

      @media (max-width: 480px) {
        font-size: 1rem;
      }
    }

    &_accordion_divider {
      border: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &_accordion_radio_button {
      height: 24px;
      width: 24px;

      &_on path {
        stroke: var(--primary-color) !important;
      }

      &_on circle {
        fill: var(--primary-color) !important;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: 50px !important;
  }

  .MuiAccordion-root {
    box-shadow: none !important;
    background-color: transparent !important;
    &:before {
      background-color: transparent !important;
    }
  }

  .MuiButtonBase-root.MuiAccordionSummary-root {
    border-bottom: 1px solid #c8bfbf;
    background-color: #fff;

    @media (min-width: 768px) {
      border: 1px solid #c8bfbf;
      border-top: none;
    }
  }
  
  .tip_wrap {
    background: transparent;
    filter: drop-shadow(-7px -7px 11px rgba(255, 255, 255, 0.2));
    filter: drop-shadow(7px 7px 11px rgba(209, 205, 199, 0.5));
  }

  .tip_tile {
    background: #efeeee;
    clip-path: polygon(0% 10px, 10px 10px, 10px 0%, calc(100% - 10px) 0%, calc(100% - 10px) 10px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 10px calc(100% - 10px), 0% calc(100% - 10px));
  }
}
