.contest {
    ol {
        padding-left: 20px;
        margin: 0;
        li {
            &:last-child {
                margin-bottom: 0;
            }

            &::marker {
                color: #000;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }
}