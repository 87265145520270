.recipe-tile-text {
    h6 {
        font-size: 0.8rem;
    }
}

.generic-card-image {
    width: 100%;
    height: 400px;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
}