@import "../../styles/mixins";

.content {
    table {
        border: 1px solid #cacbcd;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }
    
    table td, table th {
        border: 1px solid #AAAAAA;
        padding: 3px 2px;
    }

    table tbody td {
        font-size: 13px;
    }

    table tr:nth-child(even) {
        background: #F3F4F5;
    }

    table thead th {
        font-weight: bold;
    }
}