.province-switcher {
  .title {
    font-size: 2rem;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  .recipe {
    &_radio {
      height: 24px;
      width: 24px;
      margin: auto 6px;
    }

    &_accordion_radio_button {
      height: 24px;
      width: 24px;

      &_on path {
        stroke: var(--primary-color) !important;
      }

      &_on circle {
        fill: var(--primary-color) !important;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: 50px !important;
  }

  .MuiAccordion-root {
    box-shadow: none !important;
    background-color: transparent !important;
    &:before {
      background-color: transparent !important;
    }
  }

  .canada-flag {
    height: 28px;
    width: auto;
    max-width: 40px;
    object-fit: contain;
  }
}
