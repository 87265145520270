.wysiwyg p {
    margin: 0;
}

// COMPACT LAYOUT

.list_item_image {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 10px;
}

.default_size {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.default_shadow {
    box-shadow: -12px -12px 20px 0 rgba(255, 255, 255, 0.2), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);
}

// COMPACT LAYOUT

.compact {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.compact_image {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
}

// ROW LAYOUT

.row_size {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.image_for_row {
    width: 100%;
    height: 20rem;
    object-fit: cover;
}

.row_description {
    p {
        margin: 0;
    }
}