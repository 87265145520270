.author_image {
    max-width: 100%;
    height: 400px;
    object-fit: contain;
    border-radius: 10px;
}

.hero_image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.link_underline {
    text-decoration: underline;
}