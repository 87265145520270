.contest_nag {
    ol {
        padding-left: 20px;
        margin: 0;
        li {
            &:last-child {
                margin-bottom: 0;
            }

            &::marker {
                color: #000;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }

    .nag_container_top {
        box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }

    .nag_container {
        box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }
}

