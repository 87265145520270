.collection {
  &_img {
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: cover;

    &_container {
      border: none;
      padding: 0;
      width: 100%;
    }
  }
}