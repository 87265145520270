@import "../../styles/mixins";

.header {
  background-color: #000;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &_logo {
    display: flex;
    justify-content: space-evenly;

    &_icon {
      height: 68px;

      &-block {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        font-size: 11px;

        @include for-medium-phone-devices {
          font-size: 13px;
        }

        @include for-large-phone-devices {
          font-size: 15px;
        }
      }
    }

    &-beef {
      max-width: 88px;
      max-height: 177px;
    }
  }

  &_btn-back {
    color: var(--text-color);
    position: absolute;
    top: 0;
    background: transparent;
    right: 0;
    border: 0;
    font-size: 12pt;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
      text-decoration: underline;
    }
  }

  &_logo-background {
    background-color: var(--button-color);
    width: 58px;
    height: 58px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: none;
    padding: 0px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &_menu-beef {
    height: 34px;
  }

  &_french-beef {
    height: 50px;
    margin-right: 8px;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #b7b7b7;
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 0;
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root {
  border-bottom: 0;
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.header_french-beef .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input{
  padding-top: 10px;
}

.header_french-beef .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #b7b7b7;
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 0;
}

.header_french-beef .css-cio0x1-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
  background-color: rgba(0, 0, 0, 0.15);
}
