.callout {
    position: relative;
    box-sizing: border-box;
}

.callout::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -.5em;
  bottom: -1em;
  left: 25%;
  box-sizing: border-box;
  border: 0.5em solid #000;
    border-top-color: rgb(0, 0, 0);
    border-right-color: rgb(0, 0, 0);
    border-bottom-color: rgb(0, 0, 0);
    border-left-color: rgb(0, 0, 0);
  border-color: transparent transparent black transparent;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  opacity: 0.13;
}

.language-switcher {
  .recipe {
    &_radio {
      height: 24px;
      width: 24px;
      margin: auto 6px;
    }

    &_accordion_radio_button {
      height: 24px;
      width: 24px;

      &_on path {
        stroke: var(--primary-color) !important;
      }

      &_on circle {
        fill: var(--primary-color) !important;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: 50px !important;
  }

  .MuiAccordion-root {
    box-shadow: none !important;
    background-color: transparent !important;
    &:before {
      background-color: transparent !important;
    }
  }
  
  .tip_wrap {
    background: transparent;
    filter: drop-shadow(-7px -7px 11px rgba(255, 255, 255, 0.2));
    filter: drop-shadow(7px 7px 11px rgba(209, 205, 199, 0.5));
  }

  .tip_tile {
    background: #efeeee;
    clip-path: polygon(0% 10px, 10px 10px, 10px 0%, calc(100% - 10px) 0%, calc(100% - 10px) 10px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 10px calc(100% - 10px), 0% calc(100% - 10px));
  }
}
