.contact {
  background: #f2f2f2;
  
  .input-field {
    border: none !important;
    border-radius: 0;

    input, fieldset {
      padding: 0;
      border-radius: 0;
    }
  }

  .title {
    font-size: 2rem;
    color: #010101;
    font-weight: bold;

    @media (max-width: 480px) {
      font-size: 1rem !important;
    }
  }

  .brand-image {
      width: 100%;
      height: auto;
      max-height: 500px;
      object-fit: contain;
  }
}